import React, { VFC, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { size } from 'lodash/fp';
import {
  getFirstBuildingId,
  Params,
  Complex,
  ComplexRaw,
} from 'services';
import { observer } from 'mobx-react';
import { pluralize } from 'utils/pluralize';
import { Box } from 'components/styledComponents/Box';
import { typeHouseContext, } from 'context/TypeHouseProvider';
import { Block } from '../../components/Block';
import { ApartmentQueuesFilter } from './components/ApartmentQueuesFilter';
import { QueueBuildingsFilter } from './components/QueueBuildingsFilter';
import styles from './styles.module.css';
import { buildingGridStore } from '../../../LayoutPage/stores/buildingGridStore';
import { VillageTypeHouseFilter } from './components/VillageTypeHouseFilter';
import { VilligeTypeHouseList } from './components/VilligeTypeHouseList/VilligeTypeHouseList';

type Props = {
  apartmentsCount: number;
  isVillage: boolean;
  statsGroups: ComplexRaw['statsGroups'];
} & Pick<Complex, 'buildings'>;

export const QueuesBlockRender: VFC<Props> = ({
  apartmentsCount,
  buildings,
  isVillage,
  statsGroups,
}): JSX.Element => {

  const { complexId } = useParams<Pick<Params, 'complexId'>>();
  const { villageData } = useContext(typeHouseContext);

  const currentBuilding = buildingGridStore.gridBordObject?.id
    ? buildingGridStore.gridBordObject?.id.toString()
    : getFirstBuildingId()(buildings)?.toString();

  const title = isVillage
    ? `${pluralize(apartmentsCount, [
        'типовой проект',
        'типового проекта',
        'типовых проектов',
      ])} в продаже`
    : `${pluralize(apartmentsCount, [
        'квартира',
        'квартиры',
        'квартир',
      ])} в продаже в ${pluralize(size(buildings), [
        'корпусе',
        'корпусах',
        'корпусов',
      ])}`;

  if (!apartmentsCount) {
    return <div/>
  }

 
  return (
    <Block title={title} className={styles.queuesWrapper}>
      {isVillage ? (
        <>
          <VillageTypeHouseFilter
            buildingId={currentBuilding}
            complexId={complexId}
            isVillage={isVillage}
          />
        </>
      ) : (
        <>
          <QueueBuildingsFilter
            className={styles.wrapperQueueBuildingsFilter}
            buildings={buildings}
          />

          <ApartmentQueuesFilter
            buildingId={currentBuilding}
            complexId={complexId}
          />
        </>
      )}
      
      {isVillage && villageData && (
        <Box mt="20px">
          {Object.keys(villageData).map((name: string, idx: number) => (
            <VilligeTypeHouseList
              key={name}
              name={villageData[name].typeName}
              data={villageData[name]}
              statsData={statsGroups?.template}
              complexId={complexId}
            />
          ))}
        </Box>
      )}
    </Block>
  );
};

export const QueuesBlock = observer(QueuesBlockRender);
