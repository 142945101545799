import { useState, createContext, ReactNode, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { complexFilterStore, infoStore } from 'stores';
import { ComplexRaw } from 'services';
import { observer } from 'mobx-react';
const typeHouseContext = createContext<any>(null);

const { Provider } = typeHouseContext;

type Params = { complexId: string };

export type VillageDataProps = {
  [key: string] : {
    data: ComplexRaw[],
    currentPage: number,
    resultsPerPage: number, 
    total: number,
    totalPages: number
    typeName: string
  }
}

function pluralize(word: string): string {
  const plurals: Record<string, string> = {
    дуплекс: "Дуплексы",
    коттедж: "Коттеджи",
    триплекс: "Триплексы",
    таунхаус: "Таунхаусы",
  };

  const lowerWord = word.toLowerCase();
  
  return plurals[lowerWord] ? plurals[lowerWord] : word;
}


const TypeHouseProvider = observer((props: { children: ReactNode }) => {

  const initFilters = {};
  const { filters } = complexFilterStore.filters;
  const { complexId: currentComplexId } = useParams<Params>();
  const [villageFilter, setVillageFilter] = useState(filters);
  const [villageData, setVillageData] = useState<VillageDataProps>({});
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  
  const villageTypeHouseIds = useMemo(() => infoStore.dictionaries['village_type_house']?.items.map(el => ({ typeHouseId: el.id, typeName: el.value })), [infoStore.dictionaries['village_type_house']]);

  const fetchData = (typeHouse: {typeHouseId: number,typeName: string }) => {
    const searchParams = new URLSearchParams(villageFilter as any).toString();

    axios
      .get(
        `${process.env.REACT_APP_API_V1}/v1/village/cottages?complexIds=${currentComplexId}&${searchParams}&cottageStatus=template&villageTypeHouse=${typeHouse.typeHouseId}`,
      )
      .then((res) => {
        if (res?.data) {
          setVillageData((prevState) => ({
            ...prevState,
            [typeHouse.typeHouseId]: { ...res.data, typeName: pluralize(typeHouse.typeName)}
          }));
        }
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const fetchAll = () => {
    villageTypeHouseIds?.forEach(id => {
      fetchData(id)
    });
  };

  const handleChangeVillageFilter = (e: { target: any }) => {
    const { name, value } = e.target;
    setVillageFilter((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleResetVillageFilter = () => {
    setVillageFilter(initFilters);
  };

  const states = {
    villageFilter,
    villageData,
  };
 
  useEffect(() => {
    if (villageTypeHouseIds?.length) {
      fetchAll();
    }
  }, [villageTypeHouseIds]);

  useEffect(() => {
    // предотвращаем запросы из этого юзэффетка при инициализации
    if (!isInitialLoad && villageTypeHouseIds?.length) {
      fetchAll();
    }
    setIsInitialLoad(false);
  }, [villageFilter]);

  const actions = {
    handleChangeVillageFilter,
    handleResetVillageFilter,
  };

  return <Provider value={{ ...states, ...actions }} {...props} />;
});

export { TypeHouseProvider, typeHouseContext };
