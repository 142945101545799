import React from 'react';

import optionsIcon from 'assets/options.svg';
import close from 'assets/close.svg';

import { buildingFilters } from 'stores/BuildingFilters';

import { Icon } from 'components/atoms/Icon';

import {
  getFirstBuildingId,
  Params,
  Complex,
} from '../../../../../../services';

import { ButtonBase } from '../../../../../LayoutPage/components/ButtonBase';
import { OpenLayoutPageButton } from '../../../../components/OpenLayoutPageButton';
import { SmallFilters } from './SmallFilters';

import styles from './styles.module.css';
import { useMainChessFiltersPopup } from '../../../../../LayoutPage/containers/MainChessFilter/hooks';

type Props = Pick<Complex, 'buildings'> &
  Pick<Params, 'complexId'> &
  Pick<Params, 'buildingId'>;

export const ApartmentQueuesFilter = ({
  complexId,
  buildings,
  buildingId,
}: Props) => {
  const { showMainChessFilters } = useMainChessFiltersPopup();
  return (
    <div className={styles.wrapper}>
      <SmallFilters />

      <div className={styles.rightWrapper}>
        <ButtonBase
          className={styles.filterButton}
          icon={<img src={optionsIcon} alt="more filter" />}
          onClick={showMainChessFilters}
        />
        <ButtonBase
          className={styles.resetButton}
          onClick={buildingFilters.filters.reset}
          icon={<Icon size={10} alt="close" src={close} />}
        >
          Сбросить
        </ButtonBase>

        <OpenLayoutPageButton
          buildingId={buildingId || getFirstBuildingId()(buildings)?.toString()}
          complexId={parseInt(complexId || '', 10)}
          className={styles.toAppartmentsLink}
        />
      </div>
    </div>
  );
};
