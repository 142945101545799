import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import styles from './styles.module.css';
import { ComplexRaw } from 'services';

export const SwiperPlan = ({ box }: {box: ComplexRaw}) => {
  
  if (!box || !box.layouts) {
    return null; 
  }

  return (
    <Swiper
      id='boxSwiperPicture'
      spaceBetween={4}
      slidesPerView={1}
      className={styles.swiper}
      watchOverflow
      autoplay={{ delay: 1000, disableOnInteraction: true }}
      nested
      slideToClickedSlide
      pagination={{ clickable: true }}
    >
      {box.layouts.map(({ photo, id }) => {
        return <SwiperSlide key={id} >
          <div className={styles.swiperCard}  >
            <img src={photo} alt={id} className={styles.image}/>
          </div>
          <div className={styles.pagination}/>
        </SwiperSlide>
      })}
    </Swiper>
  );
};
