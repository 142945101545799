import React, { useContext } from 'react';
import { typeHouseContext } from 'context/TypeHouseProvider';
import close from 'assets/close.svg';

import { Icon } from 'components/atoms/Icon';
import {
  Params,
  Complex,
} from '../../../../../../services';

import { ButtonBase } from '../../../../../LayoutPage/components/ButtonBase';
import styles from './styles.module.css';
import { TypeHouseFilters } from './TypeHouseFilters';

type Props = Pick<Complex, 'buildings'> &
  Pick<Params, 'complexId'> &
  Pick<Params, 'buildingId'> & { isVillage?: boolean };

export const VillageTypeHouseFilter = ({
}: Props) => {
  const { handleResetVillageFilter } = useContext(typeHouseContext);

  return (
    <div className={styles.wrapper}>
      <TypeHouseFilters />

      <div className={styles.rightWrapper}>
        <ButtonBase
          className={styles.resetButton}
          onClick={handleResetVillageFilter}
          icon={<Icon size={10} alt="close" src={close} />}
        >
          Сбросить
        </ButtonBase>
      </div>
    </div>
  );
};
