import React from 'react';

import { buildingFilters } from 'stores/BuildingFilters';
import {
  FilterHeader,
  FilterBottomButtons,
} from 'components/containers/AllFiltersPopup/components';

import { observer } from 'mobx-react';
import { FiltersContent } from './components/FiltersContent';
import styles from './styles.module.css';
import { ComplexInfo } from '../../components/ComplexInfo';
import { refreshStore } from '../../stores/RefreshStore/RefreshStore';
import { complexItemStore } from '../../../../stores/ComplexItemStore/store';

export type CloseFilters = {
  onClose: () => void;
};

export const MainChessFilters = observer(({ onClose }: CloseFilters) => {
  const { target: complexData } = complexItemStore;

  return (
    <div className={styles.wrapper}>
      <div className={styles.complexInfo}>
        <ComplexInfo
          onRefresh={refreshStore.call}
          updated_at={complexData?.updated_at}
          logo={complexData?.logo || null}
          title={complexData?.title || ''}
          address={complexData?.address}
        />
      </div>
      <section className={styles.filtersWrapper}>
        <FilterHeader onClose={onClose} />
        <FiltersContent />
      </section>

      <FilterBottomButtons
        onClose={onClose}
        onCleanFilter={buildingFilters.filters.reset}
      />
    </div>
  );
});
